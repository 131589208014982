<template>
    <div>
        
        <b-card no-body>
            
            <b-card-header>
                <b-card-title>
                    Категории
                </b-card-title>
            </b-card-header>
            
            <b-table-simple class="table-striped">
                <b-thead>
                    <b-tr>
                        <b-td class="text-black font-weight-bolder">#</b-td>
                        <b-td class="text-black font-weight-bolder">Название</b-td>
                        <b-td></b-td>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr v-if="items.length === 0">
                        <b-td colspan="3" class="text-center">
                            <span class="text-muted">Нет категорий</span>
                        </b-td>
                    </b-tr>
                    <b-tr v-for="(item, index) in items" :key="index">
                        <template v-if="item.is_edit && editable[item.id]">
                            <b-td>{{ item.id }}</b-td>
                            <b-td colspan="2">
                                <b-form-input v-model="editable[item.id].name" placeholder="Название категории"></b-form-input>
                            </b-td>
                            <b-td class="text-right">
                                <b-link @click="update( item )">
                                    <feather-icon icon="CheckIcon" class="text-success" size="15" />
                                </b-link>
                                <b-link @click="setEditMode( item, false )" class="ml-1">
                                    <feather-icon icon="XIcon" class="text-danger" size="15" />
                                </b-link>
                            </b-td>
                        </template>
                        <template v-else>
                            <b-td class="text-black">{{ item.id }}</b-td>
                            <b-td class="text-black">{{ item.name }}</b-td>
                            <b-td class="text-center">
                                <b-link @click="setEditMode( item, true )">
                                    <feather-icon icon="EditIcon" class="text-primary" size="15" />
                                </b-link>
                                <b-link @click='remove( item, index )' v-b-tooltip.hover title="Удалить" class="ml-1" >
                                    <feather-icon icon="TrashIcon" class="text-danger" size="15" />
                                </b-link>
                            </b-td>
                        </template>
                    </b-tr>
                    <b-tr v-if="isOnModuleAddState">
                        <b-td>
                            <b-form-group class="form-group-with-label">
                                <validation-provider #default="{ errors }" rules="required">
                                    <span :class="{
                                        'text-muted' : errors.length > 0 ? false : true,
                                        'font-weight-bolder': errors.length > 0 ? true : false,
                                        'text-danger' : errors.length > 0 ? true : false
                                    }" style="font-size: 12px;">Название</span>                             
                                    <b-form-input
                                        id="name"
                                        v-model="name"
                                    />
                                </validation-provider>
                            </b-form-group>
                        </b-td>
                        <b-td>
                            <b-link v-b-tooltip.hover title="Добавить" class="mr-1" @click="add">
                                <feather-icon icon="CheckIcon" class="text-success" size="22" />
                            </b-link>
                            <b-link @click="isOnModuleAddState = false" v-b-tooltip.hover title="Отменить" >
                                <feather-icon icon="XIcon" class="text-danger" size="22" />
                            </b-link>
                        </b-td>
                    </b-tr>
                    <b-tr v-if="!isOnModuleAddState">
                        <b-td colspan="6">
                            <b-link @click="isOnModuleAddState = true">
                                Добавить категорию
                            </b-link>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
            
        </b-card>
            
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver } from "vee-validate";
    import App from "@/modules/contents/app"

    export default {

        data() {
            return {
                App,
                form: {
                    module: null,
                    read: false,
                    write: false,
                    edit: false,
                    delete: false
                },
                items: [],
                isOnModuleAddState: false,
                name: "",
                editable: {},
            }
        },
        methods: {
            
            remove( item, index ) {
                this.$swal({
                    title: `Подтвердите действие`,
                    text: `Вы действительно хотите удалить категорию '${item.name}'?`,
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    customClass: {
                      confirmButton: 'btn btn-danger',
                      cancelButton: 'btn btn-outline-secondary ml-1',
                    },
                    buttonsStyling: false,
                  }).then(result => {
                    if (result.value) {
                        this.$request.post("offers/categories.delete", {
                            id: item.id
                        }).then( rsp => {
                            this.items.splice( index, 1 );
                            this.$swal({
                                icon: 'success',
                                title: item.name,
                                text: 'Категория успешно удалена',
                                customClass: {
                                  confirmButton: 'btn btn-success',
                                },
                            });
                        });
                    }
                  });
            },
            
            add( args ) {
                                
                this.$request.post("offers/categories.add", {
                   name: this.name,
                }).then( rsp => {
                    this.items = rsp;
                    this.name = '';
                    this.isOnModuleAddState = false;
                });
            },

            setEditMode( item, val ) {
                              
               if( val ) {
                   this.$set( this.editable , item.id, {
                       id: item.id,
                       name: item.name,
                   });
               }
               
               this.$set( item, "is_edit", val ); 
            },

            update( category ) {
                this.$request.post("offers/categories.update", {
                    id: category.id,
                    name: this.editable[category.id].name,
                }).then( rsp => {
                    category.name = this.editable[category.id].name;
                    this.setEditMode( category, false );
                });
            },
        },
        computed: {
              
        },
        components: {
            ValidationProvider,
            ValidationObserver,
        },
        watch: {

        },
        mounted() {
            this.is_loading = true;

            this.$request.get("offers/categories").then( rsp => {
                this.items = rsp;
                this.is_loading = false;
            });
        }

    }

</script>